<style lang="less" scoped>
@import "../../assets/css/variables";

.device-chart {
  position: relative;
  .toolbar {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-bottom: 20px;
  }
  .data-chart {
    width: 100%;
    height: 280px;
  }
  .nodata {
    position: absolute;
    bottom: 0;
    z-index: 999;
    height: 280px;
    line-height: 280px;
    width: 100%;
    text-align: center;
    font-size: 14px;
    background-color: #fff;
  }
}
</style>

<template>
  <div class="device-chart">
    <div class="toolbar">
      <div class="data-list">
        <el-button
          size="small"
          v-for="(item, index) in dataTypeList"
          :type="index === activeIndex ? 'primary' : 'default'"
          :key="index"
          @click="handleItem(item, index)"
        >
          {{ item.name }}
        </el-button>

        <el-button size="small" type="success" @click="exportExcel">
          导出数据
        </el-button>
      </div>

      <div class="timerbar">
        <el-date-picker
          v-model="timeFrame"
          :clearable="false"
          style="width: 240px;"
          size="small"
          type="daterange"
          :picker-options="pickerOptions"
          placeholder="选择时间范围"
          align="right"
          @change="changeDate"
        >
        </el-date-picker>
      </div>
    </div>

    <a class="hidden" ref="downloadA" :href="downloadUrl" download></a>

    <div class="data-chart" ref="dataChart"></div>

    <div class="nodata" v-if="dataList.length === 0">没有查询到相关数据...</div>
  </div>
</template>

<script>
import moment from "moment";
import { API_ROOT } from "../../config";
import {
  getMonthSensor,
  getOutMonthSensor,
  exportDeviceDatas
} from "../../services/device";

export default {
  data() {
    return {
      moment,
      activeIndex: 0,
      downloadUrl: "",
      seriesList: [],
      outList: [],
      dataList: [],
      timeLine: [],
      timeFrame: [new Date(), new Date()],
      pickerOptions: {
        disabledDate: date => {
          if (date.getTime() < new Date().getTime()) {
            return false;
          } else {
            return true;
          }
        },
        shortcuts: [
          {
            text: "今天",
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              picker.$emit("pick", [start, end]);
            }
          },
          {
            text: "最近一个月",
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
              picker.$emit("pick", [start, end]);
            }
          },
          {
            text: "最近三个月",
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 90);
              picker.$emit("pick", [start, end]);
            }
          }
        ]
      }
    };
  },
  props: {
    dvcInfo: {
      type: Object,
      default() {
        return {};
      }
    },
    dataTypeList: {
      type: Array,
      default() {
        return [];
      }
    }
  },
  methods: {
    // 改变时间
    async changeDate() {
      await this.queryOutdoorDatas();
      this.queryIndoorDatas(this.dataTypeList[this.activeIndex]);
    },

    // 点击按钮
    handleItem(item, index) {
      this.activeIndex = index;
      this.queryIndoorDatas(item);
    },

    // 查找室内
    async queryIndoorDatas(typeItem) {
      const params = {};
      if (
        this.timeFrame.length === 2 &&
        this.timeFrame[0] &&
        this.timeFrame[1]
      ) {
        params.fromTime = moment(this.timeFrame[0])
          .startOf("day")
          .valueOf();
        params.toTime = moment(this.timeFrame[1])
          .endOf("day")
          .valueOf();
      }

      const resData = await getMonthSensor(this.dvcInfo.mac, params);
      if (resData.errorCode === 0 && resData.data) {
        const resList = this.dataList = resData.data || [];
        this.seriesList = [];
        switch (typeItem.value) {
          case "pm2_5":
            this.seriesList.push({
              name: "室外PM2.5",
              data: this.outList.map(r => r.pm2_5)
            });
            this.seriesList.push({
              name: "室内PM2.5",
              data: resList.map(item => item[typeItem.value])
            });
            break;

          case "humidity":
            this.seriesList.push({
              name: "室外湿度",
              data: this.outList.map(r => r.humidity)
            });
            this.seriesList.push({
              name: "室内湿度",
              data: resList.map(item => item[typeItem.value])
            });
            break;

          case "temp":
            this.seriesList.push({
              name: "室外温度",
              data: this.outList.map(r => r.temp_out)
            });
            this.seriesList.push({
              name: "室内温度",
              data: resList.map(item => item[typeItem.value])
            });
            break;
          default:
            this.seriesList.push({
              name: typeItem.name,
              data: resList.map(item => item[typeItem.value] || 0)
            });
            break;
        }

        this.timeLine = resList.map(item =>
          moment(item.at).format("YYYY/MM/DD")
        );
        console.warn(this.seriesList);
        console.warn(this.timeLine);

        this.initChart();
      }
    },

    // 批量导出数据
    async exportExcel() {
      const params = {};
      if (
        this.timeFrame.length === 2 &&
        this.timeFrame[0] &&
        this.timeFrame[1]
      ) {
        params.fromTime = new Date(
          moment(this.timeFrame[0]).startOf("day")
        ).getTime();
        params.toTime = new Date(
          moment(this.timeFrame[1]).endOf("day")
        ).getTime();
      }

      const resData = await exportDeviceDatas(this.dvcInfo.mac, params);
      if (resData.errorCode === 0 && resData.data) {
        this.downloadUrl = API_ROOT + "/" + resData.data.file;
        this.$nextTick(() => {
          this.$refs.downloadA.click();
        });
      }
    },

    // 查找室外
    async queryOutdoorDatas() {
      if (!this.dvcInfo.cityId) {
        return;
      }
      const params = {};
      if (
        this.timeFrame.length === 2 &&
        this.timeFrame[0] &&
        this.timeFrame[1]
      ) {
        params.fromTime = new Date(
          moment(this.timeFrame[0]).startOf("day")
        ).getTime();
        params.toTime = new Date(
          moment(this.timeFrame[1]).endOf("day")
        ).getTime();
      }

      const resData = await getOutMonthSensor(this.dvcInfo.cityId, params);
      if (resData.errorCode === 0 && resData.data) {
        const resList = resData.data || [];
        this.outList = resList;
        // this.dataList = resList.map(item => item.pm2_5);
        // this.timeLine = resList.map(item =>
        //   moment(item.at).format("YYYY/MM/DD")
        // );
      }
    },

    // 生成图表
    initChart() {
      const series = this.seriesList.map(item => {
        return {
          name: item.name,
          type: "line",
          smooth: true,
          symbol: "emptyCircle",
          symbolSize: 8,
          animation: false,
          // areaStyle: {
          //   normal: {
          //     color: new echarts.graphic.LinearGradient(
          //       0,
          //       0,
          //       0,
          //       1,
          //       [
          //         {
          //           offset: 0,
          //           color: "rgba(23, 255, 243" + ", 0.3)"
          //         },
          //         {
          //           offset: 0.8,
          //           color: "rgba(23, 255, 243" + ", 0)"
          //         }
          //       ],
          //       false
          //     ),
          //     shadowColor: "rgba(0, 0, 0, 0.1)",
          //     shadowBlur: 10
          //   }
          // },
         
          data: item.data
        };
      });
      const chartOptions = {
        color: ['rgba(23, 255, 243, 1)', 'rgba(39,240,28,0.8)'],
        grid: {
          top: "10%",
          bottom: "14%",
          left: "3%",
          right: "1%"
        },
        tooltip: {
          trigger: "axis",
          // label: {
          //   show: true
          // },
          // formatter: `{b}：{c} ${
          //   this.dataTypeList[this.activeIndex].unitSymbol
          // }`
        },
        xAxis: {
          type: "category",
          boundaryGap: true,
          axisLine: {
            show: false
          },
          splitLine: {
            show: false
          },
          axisTick: {
            show: false,
            alignWithLabel: true
          },
          data: this.timeLine
        
        },
        yAxis: {
          type: "value",
          axisLine: {
            show: false
          },
          splitLine: {
            show: true,
            lineStyle: {
              type: "dashed",
              color: "#e5e5e5"
            }
          },
          axisLabel: {
            show: true
          },
          axisTick: {
            show: false
          }
        },
        series
      };

      console.log(chartOptions);

      this.myChart.setOption(chartOptions, true);
    }
  },
  async mounted() {
    
    this.myChart = echarts.init(this.$refs.dataChart);
    window.onresize = () => {
      if (this.myChart) {
        this.myChart.resize();
      }
    };
    if (this.dvcInfo.mac) {
      await this.queryOutdoorDatas();
      this.queryIndoorDatas(this.dataTypeList[this.activeIndex]);
    }
  },
  watch: {
    "dvcInfo.mac"(newVal) {
      if (newVal) {
        this.queryIndoorDatas(this.dataTypeList[this.activeIndex]);
      }
    }
  }
};
</script>
