import * as API from "../utils/http/api";
/**
 * 创建操作日志
 */
export async function createSystemLog(params) {
  try {
    return await API.post('systemLog', {...params});
  } catch (error) {
    return error;
  }
}
