<style lang="less" scoped>
  .onsubmit {
    margin-top: 20px;
  }
</style>

<template>
  <div class="edit-name">
    <el-input v-model="deviceInfo.name"></el-input>

    <el-button
      class="onsubmit"
      type="primary"
      @click="handleUpdate">
      确定
    </el-button>
  </div>
</template>

<script>
import {updateDvcName} from '@/services/device';

export default {
  data () {
    return {
      deviceInfo: {
        mac: '',
        name: ''
      }
    }
  },
  props: {
    dvcInfo: {
      type: Object,
      default () {
        return {}
      }
    }
  },
  methods: {
    async handleUpdate () {
      const resData = await updateDvcName(this.deviceInfo.mac, this.deviceInfo.name);
      if (resData.errorCode === 0 && resData.data) {
        this.$notify.success({
          title: '成功',
          message: '修改名称成功'
        });
        this.$emit('onSubmit', resData.data);
      }
    }
  },
  mounted () {
    this.deviceInfo.mac = this.dvcInfo.mac;
    this.deviceInfo.name = this.dvcInfo.name;
  }
}
</script>
