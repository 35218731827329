/**
 * Created by xinshanwu on 2017/4/20.
 * 目录管理
 */
import * as API from "../utils/http/api";
import { PAGE_SIZE } from "../config";

/**
 * 根据ID获取单个APK版本的详情
 * @param provinces Object {name:名称,desc:描述}
 * @returns {Promise.<void>}
 */
export async function getApkVersionById(
  id,
  pager = { siez: PAGE_SIZE, page: 1 }
) {
  try {
    return await API.get("apk/" + id, pager);
  } catch (error) {
    return error;
  }
}

/**
 * 根据关键字搜索APK版本列表
 * @param provinces Object {name:名称,desc:描述}
 * @returns {Promise.<void>}
 */
export async function getApkVersions(
  params,
  pager = { siez: PAGE_SIZE, page: 1 }
) {
  try {
    return await API.get("firmwareVersion", { ...params, ...pager });
  } catch (error) {
    return error;
  }
}
/**
 * 根据deviceType 搜索固件版本
 * @param params Object {deviceType,publicNumberProductTypeId}
 */
export async function getVersionsByDvcType(
  params,
  pager = { siez: 999, page: 1 }
) {
  try {
    return await API.get("firmwareVersion/byDeviceType", {
      ...params,
      ...pager,
    });
  } catch (error) {
    return error;
  }
}

/**
 * 根据版本ID删除当前选中版本
 * @param id 目录的id
 * @returns {Promise.<*>}///
 */
export async function removeApkVersionById(id) {
  try {
    return await API.del("firmwareVersion/" + id);
  } catch (error) {
    return error;
  }
}

/**
 * 固件升级
 * params {mac，deviceTypeCode}
 */
export async function updateFirmWare(params) {
  try {
    return await API.get("firmwareVersion/updateversion", { ...params });
  } catch (error) {
    return error;
  }
}

/**
 * 批量升级
 * params
 */
export async function batchUpgrade(params) {
  try {
    return await API.get("firmwareVersion/batchUpgrade", { ...params });
  } catch (error) {
    return error;
  }
}

/**
 * 新增某个广告的版本
 * @param file Blob  文件
 * @param versionObj {zipfile:zip的文件,provincesId:广告ID,remarks:描述,versionName:版本名称}
 * @returns {Promise.<*>}
 */
export async function createApkVersion(file, versionObj) {
  try {
    let formData = new FormData();
    //这里一定要有file.name 否者上传的时候header默认以blob为name
    formData.append("file", file, file.name);
    for (let obj in versionObj) {
      formData.append(obj, versionObj[obj]);
    }
    console.log(formData);
    return await API.upload("firmwareVersion/upload", {
      formType: "file",
      formData,
    });
  } catch (error) {
    return error;
  }
}

/**
 * 根据关键字搜索系统日志记录列表
 * @param Object {key, type}
 * @returns {Promise.<void>}
 */
export async function searchSystemlogByKey(
  params,
  pager = { siez: PAGE_SIZE, page: 1 }
) {
  try {
    return await API.get("logOperate", { ...params, ...pager });
  } catch (error) {
    return error;
  }
}

/**
 * 删除系统日志记录
 * @param provinces Object {name:名称,desc:描述}
 * @returns {Promise.<void>}
 */
export async function delSystemlog(ids) {
  try {
    return await API.del("logOperate/" + ids, { ids });
  } catch (error) {
    return error;
  }
}
