<style lang="less" scoped>
  .page-container {
    font-size: 12px;
    color: #989898;

    .dvc-detail {
      padding: 20px;
      border: 1px solid rgb(235, 235, 235);
      .dvc-name {
        font-size: 16px;
        margin-right: 30px;
        color: #666666;
        line-height: 24px;
        padding: 10px 0;
      }
      .dvc-type {
        font-size: 12px;
        line-height: 21px;
        margin-right: 30px;
        color: #949494;
      }
      .el-row {
        margin-bottom: 20px;
        margin-right: 30px;
      }
      .dvcImg {
        text-align: center;
        width: 100px;
        height: 100px;
        overflow: hidden;
        background-repeat: no-repeat;
        background-size: cover;
        background-position: center;
        background-image: url("../../assets/images/defaultImg.jpg");
      }
    }

    .dvcInfo-now {
      .el-row {
        margin-bottom: 20px;
        .el-col {
          height: 60px;
          overflow: hidden;
        }
      }
      .divider {
        border-bottom: 1px solid #ebeae7;
      }
    }

    h4 {
      font-size: 16px;
      color: #333;
      font-weight: 20px;
    }
    .update {
      margin-left: 15px;
      color: #5a308f;
      &:hover {
        color: red;
      }
    }

    .qrticket {
      width: 140px;
      height: 140px;
      background-repeat: no-repeat;
      background-size: cover;
      background-position: center;
      background-image: url("../../assets/images/defaultImg.jpg");
    }
  }
  .dvcuser-item {
    box-sizing: border-box;
    overflow: hidden;
    height: 40px;
    span {
      line-height: 38px;
    }
    .manager {
      font-size: 16px;
      margin-left: 5px;
      color: #01b4cb;
    }
  }
  .dvclog-item {
    border-bottom: 1px solid rgb(235, 235, 235);
    padding: 10px 0;
  }
</style>

<template>
  <div class="page-wrapper" ref="pageScrollDom">
    <el-breadcrumb separator="/">
      <el-breadcrumb-item :to="{ path: '/device/list' }">
        活跃设备
      </el-breadcrumb-item>
      <el-breadcrumb-item>设备详情</el-breadcrumb-item>
    </el-breadcrumb>

    <div class="page-container clearfix" v-loading="loading">
      <el-row class="dvc-detail">
        <div
          class="dvcImg"
          v-lazy:background-image="
            productType.images ? API_ROOT + productType.images : defaultImg
          "
        ></div>

        <el-col :span="20">
          <div class="dvc-name">
            {{ dvcInfo.name }}
            &nbsp;
            <span
              class="text-info"
              style="cursor: pointer"
              @click="showEditName = true"
            >
              修改
            </span>
          </div>
          <el-row>
            <el-col :span="12">
              <span>设备型号：{{ dvcInfo.productTypeCode }}</span>
            </el-col>
            <el-col :span="12">
              <span>激活时间：{{ formatTime(dvcInfo.createTime) }}</span>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="12">
              <span>MAC地址：{{ dvcInfo.mac }}</span>
            </el-col>
            <el-col :span="12">
              <span>SN码：{{ dvcInfo.sn }}</span>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="12">
              当前版本号：{{ dvcInfo.swVersion ? dvcInfo.swVersion : "--" }}
              <span @click="versionShow = true" class="update">升级</span>
            </el-col>
            <el-col :span="12">
              <span>
                PM2.5最后更新时间：{{ formatTime(dvcInfo.updateTimePm) }}
              </span>
            </el-col>
          </el-row>

          <el-row>
            <el-col :span="12">
              <span>设备描述：{{ dvcInfo.remarks }}</span>
            </el-col>

            <el-col :span="12">
              <span>设备位置：{{ formatAddr(dvcInfo) }}</span>
            </el-col>
          </el-row>

          <el-row>
            <el-col :span="12">
              WIFI版本：{{ dvcInfo.wifiVer ? dvcInfo.wifiVer : "--" }}
              <span
                v-if="wifiUpVersions.length"
                @click="setVersionShow('wifi')"
                class="update"
              >
                升级
              </span>
            </el-col>
            <el-col :span="12">
              MCU版本：{{ dvcInfo.mcuVer ? dvcInfo.mcuVer : "--" }}
              <span
                v-if="mcuUpVersions.length"
                @click="setVersionShow('mcu')"
                class="update"
              >
                升级
              </span>
            </el-col>
          </el-row>
        </el-col>
        <el-col :span="4">
          <div
            v-if="dvcInfo.qrticket"
            class="qrticket pull-right"
            v-lazy:background-image="
              dvcInfo.qrticket ? API_ROOT + dvcInfo.qrticket : defaultImg
            "
          ></div>
        </el-col>
      </el-row>

      <div>
        <div class="dvcInfo-now">
          <h4>设备状态</h4>
          <el-form>
            <el-row style="margin-bottom: 0px" :gutter="0">
              <el-col :span="4">
                <el-form-item label="联网状态：">
                  <span>{{ dvcInfo.isOnline ? "在线" : "离线" }}</span>
                </el-form-item>
              </el-col>
              <el-col :span="4">
                <el-form-item label="开关机：">
                  <el-switch
                    :value="dvcStatus.power"
                    :active-value="1"
                    :inactive-value="0"
                    active-color="#13ce66"
                    inactive-color="#ff4949"
                    @change="(val) => changeStatus(val, 'power')"
                  ></el-switch>
                </el-form-item>
              </el-col>
              <el-col :span="4" v-if="levelList">
                <el-form-item label="当前挡位：">
                  <el-select
                    ref="levelEle"
                    v-if="dvcInfo.mac"
                    style="width: 80px"
                    size="small"
                    v-model="dvcStatus.level"
                    placeholder="请选择"
                    @visible-change="
                      (val) => changeSelect(val, 'level', $refs.levelEle)
                    "
                  >
                    <el-option
                      v-for="(item, index) in levelList"
                      :key="index"
                      :label="item + '档'"
                      :value="item"
                    ></el-option>
                  </el-select>
                  <span v-if="windCount">{{ windCount }}m/s</span>
                </el-form-item>
              </el-col>

              <el-col :span="4" v-if="show('ptc')">
                <el-form-item label="电加热：">
                  <el-switch
                    :value="dvcStatus.ptc"
                    :active-value="1"
                    :inactive-value="0"
                    active-color="#13ce66"
                    inactive-color="#ff4949"
                    @change="(val) => changeStatus(val, 'ptc', '电加热')"
                  ></el-switch>
                </el-form-item>
              </el-col>

              <el-col :span="4" v-if="ptcLevelList">
                <el-form-item label="电加热：">
                  <el-select
                    ref="ptcLevelEle"
                    size="small"
                    class="pull-left"
                    style="width: 80px"
                    v-model="dvcStatus.ptcLevel"
                    placeholder="请选择"
                    @visible-change="
                      (val) => changeSelect(val, 'ptcLevel', $refs.ptcLevelEle)
                    "
                  >
                    <el-option
                      v-for="(item, index) in ptcLevelList"
                      :key="index"
                      :label="item + '档'"
                      :value="item"
                    ></el-option>
                  </el-select>
                </el-form-item>
              </el-col>

              <el-col :span="4">
                <el-form-item label="工作模式：">
                  <el-select
                    ref="modeEle"
                    v-if="dvcInfo.mac"
                    size="small"
                    style="width: 80px"
                    v-model="dvcStatus.mode"
                    placeholder="请选择"
                    @visible-change="
                      (val) => changeSelect(val, 'mode', $refs.modeEle)
                    "
                  >
                    <el-option
                      v-for="(item, index) in dvcModeList"
                      :key="index"
                      :label="item.name"
                      :value="item.code"
                    ></el-option>
                  </el-select>
                </el-form-item>
              </el-col>
              <el-col :span="4" v-if="show('led')">
                <el-form-item label="LED灯：">
                  <el-switch
                    :value="dvcStatus.led"
                    :active-value="1"
                    :inactive-value="0"
                    active-color="#13ce66"
                    inactive-color="#ff4949"
                    @change="(val) => changeStatus(val, 'led', 'LED灯')"
                  ></el-switch>
                </el-form-item>
              </el-col>
              <el-col :span="4" v-if="show('childlock')">
                <el-form-item label="儿童锁：">
                  <el-switch
                    :value="dvcStatus.childlock"
                    :active-value="1"
                    :inactive-value="0"
                    active-color="#13ce66"
                    inactive-color="#ff4949"
                    @change="(val) => changeStatus(val, 'childlock', '童锁')"
                  ></el-switch>
                </el-form-item>
              </el-col>
              <el-col :span="4" v-if="show('oh')">
                <el-form-item label="负离子：">
                  <el-switch
                    :value="dvcStatus.oh"
                    :active-value="1"
                    :inactive-value="0"
                    active-color="#13ce66"
                    inactive-color="#ff4949"
                    @change="(val) => changeStatus(val, 'oh', '负离子')"
                  ></el-switch>
                </el-form-item>
              </el-col>
            </el-row>
          </el-form>

          <div class="el-row divider"></div>

          <el-row style="margin-bottom: 0px">
            <el-form>
              <el-col
                :span="4"
                v-for="(item, index) in dataPointJson"
                :key="index"
              >
                <el-form-item :label="item.name + '：'">
                  <span>
                    {{ formatValue(dvcSensors[item.code], item.code) }}
                  </span>
                </el-form-item>
              </el-col>
            </el-form>
          </el-row>

          <div class="el-row divider"></div>

          <el-row>
            <el-col :span="5">
              <span>
                一层滤芯寿命：{{
                  deviceSurplus.filter1
                    ? "剩余" + deviceSurplus.filter1 + "小时"
                    : "--"
                }}
              </span>
            </el-col>
            <el-col :span="5">
              <span>
                二层滤芯寿命：{{
                  deviceSurplus.filter2
                    ? "剩余" + deviceSurplus.filter2 + "小时"
                    : "--"
                }}
              </span>
            </el-col>
            <el-col :span="5">
              <span>
                三层滤芯寿命：{{
                  deviceSurplus.filter3
                    ? "剩余" + deviceSurplus.filter3 + "小时"
                    : "--"
                }}
              </span>
            </el-col>
            <el-col :span="5">
              <span>
                四层滤芯寿命：{{
                  deviceSurplus.filter4
                    ? "剩余" + deviceSurplus.filter4 + "小时"
                    : "--"
                }}
              </span>
            </el-col>
            <el-col :span="4">
              <el-button type="danger" size="mini" @click="handleReset">
                校准
              </el-button>
            </el-col>
          </el-row>
        </div>

        <div class="line-chart" ref="refData">
          <h4>历史数据</h4>

          <line-chart
            :dvcInfo="dvcInfo"
            :dataTypeList="dataTypeList"
          ></line-chart>
        </div>

        <!-- end dvcInfo-now -->
        <div class="data-info" ref="refUsers">
          <div>
            <h4 class="underline">绑定用户</h4>
            <div class="dvcuser-item" v-for="item in dvcUserInfo">
              <el-row>
                <el-col :span="10">
                  <el-row>
                    <el-col :span="4">
                      <span>{{ item.userName ? item.userName : "-" }}</span>
                      <i
                        title="管理员"
                        v-if="item.isManageUser"
                        class="fa fa-user manager"
                        aria-hidden="true"
                      ></i>
                    </el-col>
                    <el-col :span="4">
                      <span>{{ item.userMobile ? item.userMobile : "" }}</span>
                    </el-col>
                  </el-row>
                </el-col>
                <el-col :span="10">
                  <span>绑定时间：{{ formatTime(item.createTime) }}</span>
                </el-col>
                <el-col :span="4">
                  <!-- <el-button
                    v-if="item.isManageUser"
                    type="danger"
                    size="mini"
                    margin
                    style="margin-top: 7px"
                    @click="manageShow = true"
                    >修改</el-button
                  > -->
                  <el-button
                    :disabled="!!item.isManageUser"
                    type="danger"
                    size="mini"
                    style="margin-top: 7px"
                    @click="unbindUser(item)"
                  >
                    解绑
                  </el-button>
                </el-col>
              </el-row>
              <div style="border-bottom: 1px solid rgb(235, 235, 235)"></div>
            </div>
          </div>

          <div>
            <h4 class="">日志信息</h4>
            <div v-for="item in dvcLogList" class="el-row dvclog-item">
              <el-col :span="10">{{ item.name }}</el-col>
              <el-col :span="10">{{ item.operation }}</el-col>
              <el-col :span="4">{{ formatTime(item.createTime) }}</el-col>
            </div>
            <router-link :to="'/device/log/' + dvcInfo.mac">
              <el-button size="small" type="success" style="margin-top: 10px">
                更多日志信息
              </el-button>
            </router-link>
          </div>
        </div>
      </div>
    </div>

    <el-dialog title="升级" size="tiny" :visible.sync="versionShow">
      <el-form label-width="100px">
        <el-form-item label="固件类型：">
          <el-select
            v-model="upVersionType"
            placeholder="请选择固件类型"
            style="width: 100%"
          >
            <el-option
              v-for="item in versionList"
              :key="item.id"
              :label="item.firmwareType"
              :value="item.firmwareType"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="版本号：">
          <el-select
            v-model="upVersion"
            placeholder="请选择版本号"
            style="width: 100%"
          >
            <el-option
              v-for="item in upVersionList"
              :key="item.id"
              :label="item.swVersion"
              :value="item.swVersion"
            ></el-option>
          </el-select>
        </el-form-item>
      </el-form>
      <span slot="footer">
        <el-button
          :loading="upLoading"
          :disabled="!this.upVersion || !this.upVersionType"
          type="primary"
          @click="firmUpdate"
        >
          确定
        </el-button>
      </span>
    </el-dialog>

    <el-dialog title="管理员修改" size="tiny" :visible.sync="manageShow">
      <el-form label-width="70px">
        <el-form-item label="管理员：">
          <el-select
            v-model="managerId"
            placeholder="请选择用户"
            style="width: 100%"
          >
            <el-option
              v-for="item in dvcUserInfo"
              :key="item.userId"
              :label="item.userName"
              :value="item.userId"
            ></el-option>
          </el-select>
        </el-form-item>
      </el-form>
      <span slot="footer">
        <el-button type="primary" @click="updateManager">确定</el-button>
      </span>
    </el-dialog>

    <el-dialog title="校准" :visible.sync="dialogShow">
      <el-form :model="tempAge" label-width="120px">
        <el-form-item label="一层滤芯寿命：">
          <el-input-number
            v-model="tempAge.filter1"
            :min="0"
            :max="deviceSurplus.filter1MaxValue"
            style="width: 95%"
          ></el-input-number>
        </el-form-item>

        <el-form-item label="二层滤芯寿命：">
          <el-input-number
            v-model="tempAge.filter2"
            :min="0"
            :max="deviceSurplus.filter2MaxValue"
            style="width: 95%"
          ></el-input-number>
        </el-form-item>

        <el-form-item label="三层滤芯寿命：">
          <el-input-number
            v-model="tempAge.filter3"
            :min="0"
            :max="deviceSurplus.filter3MaxValue"
            style="width: 95%"
          ></el-input-number>
        </el-form-item>

        <el-form-item label="四层滤芯寿命：">
          <el-input-number
            v-model="tempAge.filter4"
            :min="0"
            :max="deviceSurplus.filter4MaxValue"
            style="width: 95%"
          ></el-input-number>
        </el-form-item>
      </el-form>

      <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click="checkAge">确定</el-button>
      </span>
    </el-dialog>

    <el-dialog title="修改名称" :visible.sync="showEditName">
      <edit-name :dvcInfo="dvcInfo" @onSubmit="changeNameEnd"></edit-name>
    </el-dialog>
  </div>
</template>

<script>
  import { API_ROOT } from "../../config";
  import {
    getDeviceById,
    getMonthSensor,
    getOutMonthSensor,
    getDvcUserById,
    changeDvcManager,
    unbindDvcByUser,
    surplusCheck,
    getDvclog,
    getOutstatus,
    dataStream,
    getprodTypeById,
    updateDeviceInfo,
  } from "../../services/device";

  import { mapGetters, mapActions } from "vuex";
  import { createSystemLog } from "../../services/log";
  import { updateFirmWare, getVersionsByDvcType } from "../../services/setting";
  import moment from "moment";
  import LineChart from "./line-chart";
  import EditName from "./edit-name";
  import defaultImg from "../../assets/images/defaultImg.jpg";

  export default {
    components: {
      LineChart,
      EditName,
    },
    data() {
      return {
        API_ROOT,
        defaultImg,
        loading: false,
        showEditName: false,
        dialogShow: false,
        manageShow: false,
        versionShow: false,
        isConducting: false,
        upVersion: "", // 升级版本号
        upVersionType: "", // 升级固件类型 mcu wifi
        upLoading: false, // 升级loading
        managerId: "",
        dvcLogList: [],
        dvcModeList: [],
        versionList: [],
        dataTypeList: [
          {
            name: "PM2.5",
            value: "pm25",
            maxValue: 500,
            unitSymbol: "ug/m³",
          },
          { name: "二氧化碳", value: "co2", maxValue: 2000, unitSymbol: "ppm" },
          { name: "湿度", value: "humidity", maxValue: 100, unitSymbol: "%" },
          { name: "温度", value: "temp", maxValue: 100, unitSymbol: "℃" },
          { name: "TVOC", value: "tvoc", maxValue: 100, unitSymbol: "" },
          { name: "HCHO", value: "hcho", maxValue: 100, unitSymbol: "" },
        ],
        tempAge: {},
        productType: {},
        deviceSurplus: {
          filter1: "",
          filter2: "",
          filter3: "",
          filter4: "",
        },
        dvcInfo: {
          images: "",
        },
        brandInfo: {
          list: [],
        },
        outStatus: {
          pm2_5: 0,
        },
        dvcStatus: {
          power: 0,
          level: 0,
          ptc: 0,
          ptcLevel: 0,
          mode: 0,
          newwind: 0,
          backwind: 0,
          led: 0,
          childlock: 0,
          oh: 0,
          uv: 0,
        },
        dvcSensors: {
          pm2_5: 0,
          co2: 0,
          temp: 0,
          temp_out: 0,
          humidity_out: 0,
          tvoc: 0,
          hcho: 0,
          humidity: 0,
        },
        dvcUserInfo: {},
        currentMode: null,
        tableData: [],
      };
    },
    computed: {
      ...mapGetters({
        user: "userInfo",
      }),
      dataPointJson() {
        let res =
          this.productType && this.productType.dataPointJson
            ? JSON.parse(this.productType.dataPointJson)
            : {};
        return res;
      },
      windCount() {
        if (this.dvcModeList.length && this.dvcStatus) {
          const currentMode = this.dvcModeList.find(
            (item) => item.code == this.dvcStatus.mode
          );
          if (currentMode) {
            return currentMode.wind || 0;
          }
        }
        return 0;
      },
      levelList() {
        const currentMode = this.currentMode;
        if (currentMode) {
          const funcList = currentMode.funcList || [];
          const level = funcList.find((i) => i.levelCode === "level") || {};
          return +level.max || 0;
        } else {
          return 0;
        }
      },
      ptcLevelList() {
        const currentMode = this.currentMode;
        if (currentMode) {
          const funcList = currentMode.funcList || [];
          const ptcLevel =
            funcList.find((i) => i.levelCode === "ptcLevel") || {};
          return +ptcLevel.max || 0;
        } else {
          return 0;
        }
      },
      wifiUpVersions() {
        return this.versionList.filter((item) => item.firmwareType === "wifi");
      },
      mcuUpVersions() {
        return this.versionList.filter((item) => item.firmwareType === "mcu");
      },
      upVersionList() {
        return this.upVersionType === "wifi"
          ? this.wifiUpVersions
          : this.mcuUpVersions;
      },
      // currentMode() {
      //   return (this.dvcModeList || []).find(
      //     item => item.code == this.dvcStatus.mode
      //   )
      // }
    },
    methods: {
      // 设置版本升级弹窗相关信息
      setVersionShow(type = "") {
        this.upVersionType = type;
        this.versionShow = true;
      },
      // 格式化时间
      formatTime(time) {
        return time ? moment(time).format("YYYY-MM-DD HH:mm:ss") : "";
      },

      //格式化采集数据
      formatValue(value, code) {
        if (value === 0) {
          return 0;
        } else if (value) {
          const item = this.tableData.find((item) => item.code === code);
          return value + item.unitSymbol;
        } else {
          return "--";
        }
      },

      formatAddr(info) {
        let addr = info.cityName + info.districtName + info.address;
        if (info.provinceName !== info.cityName) {
          addr = info.provinceName = addr;
        }
        return addr ? addr.replace("null", "") : "";
      },

      show(fn) {
        if (this.currentMode) {
          const funcList = this.currentMode.funcList || [];
          return funcList.some((item) => item.code === fn);
        }

        return false;
      },

      handleReset() {
        this.tempAge.filter1 = this.deviceSurplus.filter1;
        this.tempAge.filter2 = this.deviceSurplus.filter2;
        this.tempAge.filter3 = this.deviceSurplus.filter3;
        this.tempAge.filter4 = this.deviceSurplus.filter4;
        this.dialogShow = true;
      },

      changeNameEnd(deviceInfo) {
        this.showEditName = false;
        this.dvcInfo.name = deviceInfo.name;
      },

      // 滚动到某元素
      animateScroll(element, speed) {
        let scrollElemet = this.$refs.pageScrollDom;
        let rect = element.getBoundingClientRect();
        //获取元素相对窗口的top值，此处应加上窗口本身的偏移
        let top = window.pageYOffset + rect.top;
        let currentTop = 0;
        let requestId;
        //采用requestAnimationFrame，平滑动画
        function step(timestamp) {
          currentTop += speed;
          if (currentTop <= top) {
            scrollElemet.scrollTo(0, currentTop);
            requestId = window.requestAnimationFrame(step);
          } else {
            window.cancelAnimationFrame(requestId);
          }
        }
        window.requestAnimationFrame(step);
      },

      // 启动mqtt的监听
      listenDevice() {
        window.app.$on("mqttMsg", ({ topic, message }) => {
          // 监听设备在线状态
          if (!topic.includes(this.dvcInfo.mac)) return;
          if (topic.includes("connected")) {
            this.dvcInfo.isOnline = true;
          }
          if (topic.includes("disconnected")) {
            this.dvcInfo.isOnline = false;
          }

          // console.warn(topic);
          // console.warn(message);

          // 监听设备上报数据点
          if (topic.includes("sensor")) {
            this.dvcSensors.pm2_5 = message["pm2.5"];
            this.dvcSensors.temp = message.temp;
            this.dvcSensors.co2 = message.co2;
            this.dvcSensors.temp_out = message.temp_out;
            this.dvcSensors.humidity_out = message.humidity_out || 0;
            this.dvcSensors.tvoc = message.tvoc;
            this.dvcSensors.hcho = message.hcho;
            this.dvcSensors.humidity = message.humidity;
          }

          // 监听设备上报的状态
          if (topic.includes("sys_status")) {
            console.warn(message);
            this.dvcStatus.level = message.level;
            this.dvcStatus.power = message.power;
            this.dvcStatus.ptc = message.ptc;
            this.dvcStatus.ptcLevel = message.ptcLevel;
            this.dvcStatus.mode = message.mode;
            this.dvcStatus.newwind = message.newwind || 0;
            this.dvcStatus.backwind = message.backwind || 0;
            this.dvcStatus.led = message.led;
            this.dvcStatus.childlock = message.childLock;
            this.dvcStatus.oh = message.oh;
            this.dvcStatus.uv = message.uv;
          }

          // 监听设备的滤芯数据
          if (topic.includes("sys_surplus")) {
            this.deviceSurplus.filter1 = message[1];
            this.deviceSurplus.filter2 = message[2];
            this.deviceSurplus.filter3 = message[3];
            this.deviceSurplus.filter4 = message[4];
          }
        });
      },

      // 改变模式和挡位
      changeSelect(value, attr, ele) {
        if (!value) {
          const message = {};
          message[attr] = ele.value;

          const topic = `/client/${this.dvcInfo.belongsType}/${this.dvcInfo.mac}/cmd`;
          this.$mqtt.publish(topic, JSON.stringify(message));

          if (attr == "mode") {
            this.createOperaLog("调整模式");
          } else if (attr == "ptcLevel") {
            this.createOperaLog("调整PTC挡位");
          } else if (attr == "level") {
            this.createOperaLog("调整风速挡位");
          }
        }
      },

      // 开关命令统一一下
      changeStatus(value, attr, logName) {
        const message = {};
        message[attr] = value;
        this.dvcStatus[attr] = value;
        const topic = `/client/FA/${this.dvcInfo.mac}/cmd`;
        this.$mqtt.publish(topic, JSON.stringify(message));
        this.createOperaLog(`${value == 1 ? "开启" : "关闭"}${logName || ""}`);
      },

      //操作日志
      async createOperaLog(opera) {
        const logInfo = {
          mac: this.dvcInfo.mac,
          name: this.user.name,
          userName: this.user.username,
          deviceName: this.dvcInfo.name,
          operation: opera,
          remarks: opera,
        };
        const responseData = await createSystemLog(logInfo);
      },

      //固件升级
      async firmUpdate() {
        this.upLoading = true;
        let params = {
          mac: this.dvcInfo.mac,
          newver: this.upVersion,
          firmwareType: this.upVersionType,
        };
        updateFirmWare(params).then((res) => {
          if (res && res.errorCode === 0) {
            if (res.data.result === true) {
              this.$notify.success({
                title: "成功",
                message: "升级成功",
              });
            } else {
              this.$notify.error({
                title: "失败",
                message: "升级失败",
              });
            }
            this.versionShow = false;
            this.upLoading = false;
          }
        });
      },

      //获取versionList
      async getVersionList() {
        const params = {
          deviceTypeId: this.dvcInfo.deviceTypeId,
          productTypeId: this.dvcInfo.productTypeId,
        };
        const res = await getVersionsByDvcType(params);
        if (res && res.errorCode === 0 && res.data) {
          this.versionList = res.data || [];
        }
      },

      //更新设备管理员
      async updateManager() {
        this.manageShow = false;
        const res = await changeDvcManager(this.dvcInfo.id, this.managerId);
        if (res && res.errorCode === 0 && res.data == "done") {
          this.queryDevUser(this.dvcInfo.id);
          this.$notify.success({
            title: "成功",
            message: "修改成功",
          });
        } else {
          this.$notify.success({
            title: "失败",
            message: "修改失败",
          });
        }
      },

      //获取设备信息
      async queryDevInfo(id) {
        this.loading = true;
        const res = await getDeviceById(id);
        if (res.errorCode === 0 && res.data) {
          this.dvcInfo = res.data;
          this.productType = res.data.productType || {};
          this.deviceSurplus = res.data.deviceSurplus || {};
          if (this.dvcInfo.deviceStatus) {
            this.dvcStatus = {
              power: +this.dvcInfo.deviceStatus.power || 0,
              level: +this.dvcInfo.deviceStatus.level || 0,
              ptc: +this.dvcInfo.deviceStatus.ptc || 0,
              ptcLevel: +this.dvcInfo.deviceStatus.ptcLevel || 0,
              mode: +this.dvcInfo.deviceStatus.mode || 1,
              newwind: +this.dvcInfo.deviceStatus.newwind || 0,
              backwind: +this.dvcInfo.deviceStatus.backwind || 0,
              childlock: +this.dvcInfo.deviceStatus.childlock || 0,
              led: +this.dvcInfo.deviceStatus.led || 0,
              oh: +this.dvcInfo.deviceStatus.oh || 0,
              uv: +this.dvcInfo.deviceStatus.uv || 0,
            };
          }
          this.dvcSensors = this.dvcInfo.deviceSensor || {};
          this.getDvclog(res.data.mac);
          this.getVersionList();
          this.parseMode(res.data.productType);
          const city = this.dvcInfo.cityId;
          if (city) {
            this.getOutStatus(city);
          }

          this.$mqtt.subscribe("/+/+/" + this.dvcInfo.mac + "/+/+/+");
          this.$mqtt.subscribe("/" + this.dvcInfo.mac + "/disconnected");

          // 启动监听了
          this.listenDevice();

          this.$nextTick(() => {
            const query = this.$route.query;
            if (query.toElement) {
              this.animateScroll(this.$refs[query.toElement], 100);
            }
          });
        }
        this.loading = false;
      },

      // 获取设备操作日志
      async getDvclog(mac) {
        const res = await getDvclog(mac, { page: 1, size: 5 });
        if (res && res.errorCode === 0 && res.data) {
          this.dvcLogList = res.data.list || [];
        }
      },

      //获取室外PM2.5
      async getOutStatus(city) {
        const res = await getOutstatus(city);
        if (res && res.errorCode === 0 && res.data) {
          this.outStatus = res.data;
        }
      },

      //获取设备用户信息
      async queryDevUser(id) {
        const res = await getDvcUserById(id);
        if (res && res.errorCode === 0 && res.data.list) {
          this.dvcUserInfo = res.data.list || [];
          this.dvcUserInfo.forEach((item) => {
            if (item.isManageUser) {
              this.managerId = item.userId;
            }
          });
        }
        console.error(res);
      },

      parseMode(productType) {
        try {
          const modeJson = JSON.parse(productType.modeJson);
          const modeList = modeJson.modeList || [];
          this.dvcModeList = modeList.map((item) => {
            item.code = +item.value;
            if (item.code == this.dvcStatus.mode) {
              this.currentMode = item;
            }
            return item;
          });
        } catch (error) {
          console.log(error);
        }
      },

      // 滤芯寿命校准
      async checkAge() {
        const params = {
          mac: this.dvcInfo.mac,
          filter1: this.tempAge.filter1,
          filter2: this.tempAge.filter2,
          filter3: this.tempAge.filter3,
          filter4: this.tempAge.filter4,
        };
        const res = await surplusCheck(params);
        if (res.errorCode === 0 && res.data === "done") {
          setTimeout(() => {
            this.queryDevInfo(this.$route.params.id);
          }, 1500);
          this.dialogShow = false;
          this.$notify.success({
            title: "成功",
            message: "校准成功",
          });
        } else {
          this.$notify.error({
            title: "错误",
            message: "校准失败",
          });
        }
      },

      // 解绑
      unbindUser(item) {
        this.$confirm("确认解除与此用户的绑定关系?", "提示", {
          type: "warning",
        })
          .then(() => {
            this.listLoading = true;
            unbindDvcByUser(item.userId, this.dvcInfo.mac).then((res) => {
              this.listLoading = false;
              if (res.errorCode === 0) {
                this.$notify.success({
                  title: "成功",
                  message: "解绑成功",
                });
                this.queryDevUser(this.$route.params.id);
              }
            });
          })
          .catch(() => {});
      },
      /**
       * 获取产品型号列表
       */
      async dataStream() {
        let Param = { type: "sensor" };
        const responseData = await dataStream(Param, { siez: 999, page: 1 });
        if (responseData && responseData.data && responseData.errorCode === 0) {
          this.tableData = responseData.data.list || [];
          // alert(1)
        }
      },
    },
    // beforeRouteLeave(to, from, next) {
    //   this.$mqtt.unsubscribe("/+/+/" + this.dvcInfo.mac + "/+/+/+");
    //   this.$mqtt.unsubscribe("/" + this.dvcInfo.mac + "/disconnected");
    //   next();
    // },
    created() {
      const id = this.$route.params.id;
      this.queryDevInfo(id);
      this.queryDevUser(id);
      this.dataStream();
    },
    watch: {
      "dvcStatus.mode"(newval) {
        if (!newval && newval != 0) {
          this.currentMode = this.dvcModeList.find(
            (item) => item.code == this.dvcStatus.mode
          );
        }
      },
    },
  };
</script>
